.header {
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 73px !important; 
    padding-top: 12px;
    padding-bottom: 4px;
    &-container {
        display: flex;
        @media screen and (max-width: 767px) {
            justify-content: space-between;
            align-items: center;
        }
    } 
    .nav {
      &-link {
          font-weight: 700;
          color: #17469E !important;
          font-size: 20px;
          display: inline-block;
          line-height: calc(27 / 20);
          &.active {
              color: #E97025  !important;
          }
          padding-right: unset !important;
          padding-left:  unset !important;
      }
      &-item:first-child {
        @media (min-width: 992px){
          padding-left: 45px;
        }
        padding-right: 45px;
      }
      &-item:nth-child(2) {
        padding-right: 44px;
      }
    } 
    .navbar-toggler {
      margin-right: 10px;
    }
    .profile {
      padding-right: 35px;
          .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
      .info {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 9px;
        .name {
          color: #000000;
          font-size: 12px;
          line-height: calc(16 / 12);
          font-weight: 700;
          margin-top: 5px;
        }
        .position {
          color: #515151;
          font-size: 12px;
          line-height: calc(13 / 10);
          font-weight: 700;
        }
        .logout {
          cursor: pointer;
          span {
            font-size: 12px;
            line-height: calc(13 / 10);
            font-weight: 700;
            color: #515151;
          }
        }
      }
    }
 
}

@media (max-width: 992px){
  .collapse {
    .profile {
      padding-left: 58px;
    }
  } 
}
