// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// @font-face {
//   font-family: 'Tahoma';
//   src: url('./../../public/fonts/vendor/TAHOMA_0.ttf')
//     format('woff');
//   font-weight: 400;
// }

// @font-face {
//   font-family: 'Tahoma';
//   src: url('./../../public/fonts/vendor/TAHOMAB0.ttf')
//     format('woff');
//   font-weight: 700;
// }
// Variables
@import 'variables';
@import '_home';
@import '_homeDetail';
@import '_viewPost';
@import '_header';
// Bootstrap
@import '~bootstrap/scss/bootstrap';
// Font Awesome

@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
// sweet alert
@import 'sweetalert2/src/sweetalert2.scss';
// import 'sweetalert2/src/sweetalert2.scss'

// custom antd
@import 'customAntd';

body {
  font-family: Arial, sans-serif !important;
}

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.blue {
  color: $blue;
}
.indigo {
  color: $indigo;
}
.purple {
  color: $purple;
}
.pink {
  color: $pink;
}
.red {
  color: $red;
}
.orange {
  color: $orange;
}
.yellow {
  color: $yellow;
}
.green {
  color: $green;
}
.teal {
  color: $teal;
}
.cyan {
  color: $cyan;
}

// .content-header{
//   padding: 10px 0.5rem !important;
// }

*::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.6rem;
  background-color: #e3e2e9;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #b8b8bb;
  border-radius: 5px;

  &:hover {
    background-color: #a2a2a5;
  }
}

.xy-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cusor-pointer {
  &:hover {
    cursor: pointer;
  }
}
