.ant-form-item-label {
  line-height: 20px !important;
}

.ant-input-search {
  input {
    border-radius: 6px !important;
  }
}

.ant-select-selector {
  border-radius: 6px !important;
  min-height: 40px !important;
  border-color: #c4c4c4 !important;
  box-shadow: none !important;
  &:focus {
    box-shadow: none !important;
  }
  .ant-select-selection-placeholder {
    line-height: 40px !important;
  }

  input {
    height: 100% !important;
  }
}

.ant-btn {
  border-radius: 6px !important;
  font-weight: 700 !important;
  height: 40px !important;
}

.ant-table-thead > tr > th {
  background: #ffff !important;
  border-bottom: 1px solid #f0f0f0 !important;
  font-weight: 700 !important;
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0 !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*' !important;
}

.ant-form-item-required::before {
  content: none !important;
}

.ant-notification-notice-message {
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 136.02% !important;
  color: #ffffff !important;
}
.ant-table-header {
  background: #fff !important;
}

.ant-input-search {
  input {
    height: 40px;
  }
}

.ant-btn-primary {
  background-color: #1280bf !important;
  border-color: #1280bf !important;
}

.ant-input {
  height: 40px !important;
  border-radius: 5px !important;
  border-color: #c4c4c4 !important;
}

.ant-input:focus {
  box-shadow: none !important;
}

.ant-form-item-control.has-error {
  .ant-input {
    border-color: #f5222d !important;
  }
}

.ant-form-item-control.has-error {
  .ant-select-selection {
    border-color: #f5222d !important;
  }
}

textarea.ant-input {
  height: auto !important;
}

.ant-upload-list {
  display: flex;
  flex-wrap: wrap;
}

.ant-upload-list-item {
  padding-right: 20px !important;
  margin-right: 10px !important;
}

.ant-upload-list-item-card-actions {
  top: -4px;
  opacity: 1 !important;
}

.ant-upload-list-item-info:hover {
  background-color: none !important;
  background: none !important;
}

.ant-upload-list-item:hover {
  background: rgb(240, 240, 240);
  border-radius: 5px;
}

.ant-select-selection--multiple .ant-select-selection__choice__remove {
  font-size: 16px !important;
  top: -2px !important;
}

.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  height: 28px !important;
  line-height: 26px !important;
  border-radius: 5px;
  margin-top: 6px !important;
}

.ant-select-selection--multiple .ant-select-selection__choice {
  background: #ededed !important;
}

.ant-table-placeholder {
  border-bottom: 1px solid #f0f0f0 !important;
  border-top: 1px solid #f0f0f0 !important;
}

.ant-form-item-explain.ant-form-item-explain-connected {
  .ant-form-item-explain-error {
    margin-top: 4px !important;
    margin-bottom: 8px !important;
    font-size: 12px;
    position: relative;
    &::before {
      content: '*';
      color: #f5222d;
      margin-right: 4px;
    }
  }
}

.ant-modal-title {
  text-align: center !important;
  font-weight: 700 !important;
  font-size: 24px !important;
}

.modal-feedback .ant-modal-title{
  font-size: 18px !important;
}
.modal-detail-feedback{
  .ant-modal-title{
    font-size: 26px !important;
    text-align: left !important;
    color: #17469E;
  }
  .ant-modal-header{
    border-bottom: none;
  }
  .ant-modal-footer{
    border-top: none;
    .ant-btn{
      height: 40px !important;
    }
  }
}

.modal-confirm-feedback{
  .ant-modal-title{
    font-size: 26px !important;
    text-align: left !important;
    color: #17469E;
  }
  .ant-modal-header{
    border-bottom: none;
  }
  .ant-modal-body{
    padding: 16px 24px;
  }
  .ant-modal-footer{
    border-top: none;
  }
}


.modal-feedback,
.modal-preview {
  .ant-modal-header{
    border-bottom: none !important;
  }
}
.modal-preview .ant-modal-title{
  text-align: left !important;
  color: #17469E;
  border-bottom: none !important;
}
.ant-modal {
  border-radius: 5px !important;
  overflow: hidden;
  border-bottom: none !important;
}

.ant-form-item-has-error {
  input {
    border-color: #ff4d4f !important;
  }
}

.ant-select-selection-item {
  line-height: 38px !important;
  border-radius: 5px !important;
  .ant-select-selection-item-remove {
    display: flex !important;
    align-items: center;
  }
}

.ant-select-clear {
  display: inline-flex !important;
}

.ant-select-multiple .ant-select-selection-item {
  align-items: center;
}

.ant-modal-confirm-delete {
  p {
    margin: 0;
  }
  .ant-modal-confirm-title {
    font-weight: 700 !important;
    font-size: 18px !important;
    color: #e41d1d !important;
  }
  .ant-modal-confirm-content {
    color: #e41d1d !important;
  }
}

.ant-modal-confirm-edit {
  p {
    margin: 0;
  }
  .ant-modal-confirm-title {
    font-weight: 700 !important;
    font-size: 18px !important;
  }
  .ant-modal-confirm-content {
    color: #515151 !important;
  }
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: unset !important;
}
