.view-post-wrapper {
  .ck-content {
    .table {
        max-width: 100vh;
        width: 100vh;
        display: block !important;
        overflow: auto;
        max-height: calc(100vh - 500px);
        margin: unset;
        &::-webkit-scrollbar {
          width: 8px !important;
          cursor: default;
        }
      
        &::-webkit-scrollbar-track {
          background: transparent;
        }
      
        &::-webkit-scrollbar-thumb {
          border-radius: 12px;
        }
      }
    }
}