.home-detail {
  background: #fff;
  position: relative;
  // height: calc(100vh - 90px);
  min-height: 100%;
  // overflow-y: auto;
  border-radius: 5px;
  .ck-content {
    .table {
      max-width: 100%;
      width: 100%;
      display: block !important;
      overflow: auto;
      width: 100%;
      max-height: calc(100vh - 300px);
    }
    table {
      &::-webkit-scrollbar {
        width: 8px !important;
        cursor: default;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 12px;
      }
    }
  }
  .detail {
    padding: 30px 51px 30px 58px;
    .go-back {
      span {
        color: #929292;
        font-weight: 900;
        font-size: 16px;
        line-height: calc(2 / 16);
        margin-left: 4px;
        cursor: pointer;
      }
      margin-bottom: 24px;
      svg {
        margin-bottom: 5px;
      }
    }
    &-title {
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 136%;
      color: #000000;
      margin-bottom: 10px;
    }
    .created-date {
      font-weight: 400;
      font-size: 16px;
      line-height: 136%;
      color: #000000;
      margin-bottom: 10px;
    }
    .creator {
      font-weight: 400;
      font-size: 16px;
      line-height: 136%;
      color: #000000;
      margin-bottom: 10px;
    }
    .categories {
      font-weight: 400;
      font-size: 16px;
      line-height: 136%;
      color: #000000;
      margin-bottom: 10px;
    }
    .description {
      font-weight: 400;
      font-size: 16px;
      line-height: 136%;
      color: #000000;
      margin-bottom: 42px;
    }
    &-content {
      font-weight: 400;
      font-size: 16px;
      line-height: 136%;
      color: #000000;
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 136%;
        color: #000000;
      }
      margin-bottom: 24px;
    }
    .attachments-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 136%;
      color: #000000;
      margin-bottom: 9px;
    }
    .highlight-des {
      background-color: #E5B32A !important;
    }
    .highlight {
      background-color: #A2D8F6 !important;
    }
    .attachements {
      display: flex;
      gap: 0px 25px;
      flex-wrap: wrap;
      margin-bottom: 90px;
      .attachements-item {
        display: flex;
        .name-file {
          margin-left: 7px;
          font-weight: 400;
          font-size: 14px;
          line-height: 136%;
          color: #000000;
          margin-top: 5px;
          display: inline-block;
        }
      }
    }
  }
  .detail-view {
    padding-right: 67px;
    padding-left: 31px;
    position: sticky;
    top: 0;
    .view-title {
      font-weight: 700;
      font-size: 26px;
      line-height: 136%;
      color: #00c62b;
      margin-top: 69px;
      margin-bottom: 17px;

      @media only screen and (max-width: 1366px) {
        margin-top: 20px;
      }
    }
    .view-list {
      .view-item {
        margin-bottom: 31px;
        @media only screen and (max-width: 1366px) {
          margin-bottom: 10px;
        }
        &-title {
          font-weight: 700;
          font-size: 20px;
          line-height: 136%;
          color: #000000;
          margin-bottom: 15px;
          @media only screen and (max-width: 1366px) {
            margin-bottom: 6px;
          }
        }
        &-content {
          font-weight: 400;
          font-size: 16px;
          line-height: 136%;
          color: #000000;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          height: 40px;
        }
      }
    }
    .pagination {
      width: 100%;
      margin: auto;
      &-page {
        margin: auto;
        margin-top: 10px;
      }
      .ant-pagination-next {
        color: #0047ff;
      }
      .ant-pagination-prev {
        color: #0047ff;
      }
      .ant-pagination-item {
        border: none;
      }
      svg {
        margin-bottom: 5px;
      }
    }
  }
}
.pagination-wrapper {
  display: flex;
  margin-top: 30px;
  justify-content: right;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  width: 100%;
  .total-page {
    color: rgba(0, 0, 0, 0.87);
    margin: 0 30px;
  }
  .dropdown-icon {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54);
    position: relative;
    top: -7px;
    left: 4px;
  }
  .pagination-btn {
    border: none;
    margin-bottom: 0;
    width: unset;
    .icon-btn {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.54);
    }
  }
  :deep(.ant-select-selection-item) {
    display: flex !important;
    align-items: center;
  }
}
@media (max-width: 992px) {
  .collapse {
    .profile {
      padding-left: 58px;
    }
  }
}
