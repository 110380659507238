.home {
  .ant-pagination-item-active{
    border: 1px solid #1890ff !important;
  }
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 98px);
  border-radius: 5px;
  .title {
    font-size: 26px;
    font-weight: 600;
    line-height: calc(35 / 26);
    color: #000;
    padding-top: 27px;
    margin-bottom: 6px;
    text-transform: uppercase;
  }
  mark{
    padding: 0 !important;
    background-color: #a2d8f6;
  }
  .content {
    font-size: 16px;
    font-weight: 400;
    line-height: calc(21 / 16);
    color: #000;
    margin-bottom: 45px;
    @media only screen and (max-width: 1366px) {
      margin-bottom: 20px;
    }
  }
  &.height-vh {
    height: calc(100vh - 115px);
  }
  &.height-percent {
    height: 100%;
  }

  .search-wrapper {
    background-color: white;
    position: sticky;
    top: 0px;
    max-width: 800px;
    width: 100%;
    padding-bottom: 10px;
    .search {
      max-width: 800px;
      width: 100%;
      border: 2px solid green;
      position: relative;
      $border: 2px;
      background-color: white;
      background-clip: padding-box;
      border: solid $border transparent;
      border-radius: 2em;
      &::before{
        content: '';
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        margin: -$border;
        border-radius: inherit;
        z-index: -1;
        background: linear-gradient(to bottom, #E4B52A, #ED2424);
      }
      .btn-search {
        border: 2px solid #000000;
        border-right: none;
        border-top-left-radius: 47px !important;
        border-bottom-left-radius: 47px !important;
        height: 51px !important;
        font-weight: 600;
        font-size: 16px;
        line-height: 193%;
        color: #929292;
        padding-left: 31px;
        padding-right: 23px;
        span {
          margin-right: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 160px;
          margin-top: 6px;
          display: inline-block;
        }
        svg {
          display: inline-block;
          margin-bottom: 20px;
        }
      }
      &-input {
        width: calc(100% - 180px);
        @media (max-width: 700px) {
          width: calc(100% - 180px);
        }
        position: relative;
        input {
          border-radius: 2em;
          display: block;
          width: 100%;
          background-size: 15px 15px;
          font-size: 16px;
          border: none;
          padding: 4px;
          padding-left: 10px;
          height: 51px !important;
          font-weight: 400;
          line-height: 136%;
          color: #000000;
          :hover {
            padding-left: 6px;
            border: none;
          }
          :focus-visible {
            outline: none !important;
          }
        }

        .item {
          width: calc(83% - 115px);
          @media (max-width: 700px) {
            width: calc(73% - 115px);
          }
          color: #000000;
          font-size: 16px;
          line-height: 136%;
          font-weight: 600;
          padding-left: 20px;
          p {
            margin: 0px;
          }
        }
      }
      .list-suggest {
        margin-top: 10px;
        padding: 22px 0px;
        background: #ffffff;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        position: absolute;
        width: 100%;
      }
    }

    .label-search {
      width: 180px;
      background: #ffffff;
      border-radius: 2em;
      div {
        color: #00c62b;
        font-weight: 400;
        font-size: 14px;
        line-height: 136%;
        height: 51px;
        padding: 15px;
        cursor: pointer;
        text-align: right;
      }
    }

    .categories {
      max-width: 800px;
      width: 100%;
      display: flex;
      gap: 0px 25px;
      align-items: center;
      flex-wrap: wrap;
      div {
        font-weight: 400;
        font-size: 14px;
        line-height: 136%;
        color: #0047ff;
        margin-top: 10px;
      }
    }
  }

  .search-list {
    max-width: 800px;
    width: 100%;
    margin-top: 10px;
    .search-item {
      margin-bottom: 19px;
      &-title {
        line-height: 136%;
        margin-bottom: 5px;
        cursor: pointer;
      }
      &-date {
        font-weight: 400;
        font-size: 14px;
        line-height: 136%;
        color: #000000;
        margin-bottom: 16px;
      }
      &-content {
        font-weight: 400;
        font-size: 16px;
        line-height: 136%;
        color: #000000;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        cursor: pointer;
        height: 44px;
        overflow: hidden;
      }
    }
    .search-item:nth-child(10) {
      margin-bottom: 0px;
    }
  }
  .total-list {
    max-width: 800px;
    width: 100%;
    margin-top: 8px;
    color: #000000;
    font-weight: 400;
    font-size: 11px;
    line-height: 136%;
    a {
      color: #0047ff !important;
      font-weight: 400;
      font-size: 11px;
      line-height: 136%;
    }
  }
  .pagination {
    position: sticky;
    bottom: 0;
    background-color: white;
    max-width: 800px;
    width: 100%;
    margin-top: auto;
    padding: 18px 0;
    &-page {
      margin: auto;
      margin-top: 10px;
    }
    .ant-pagination-next {
      color: #0047ff;
    }
    .ant-pagination-prev {
      color: #0047ff;
    }
    .ant-pagination-item {
      border: none;
    }
    svg {
      margin-bottom: 5px;
    }
  }
  :focus-visible {
    outline: none !important;
  }
  .title-top-views {
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    justify-content: left;
    width: 100%;
    margin: auto;
    margin-bottom: 21px !important;
    font-family: Arial, sans-serif !important;;
    font-style: normal;
    font-weight: 600;
    line-height: 136%;
    color: #000000;
  }
  .top-views {
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px !important;
    .icon-prev {
      margin-right: 5px;
      cursor: pointer;
    }
    .icon-next {
      margin-left: 5px;
      cursor: pointer;
    }
    .views-content-item:has(div) {
      display: block;
    }
    .views-content {
      display: grid;
      // grid-template-columns: repeat(3, 1fr);
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      grid-template-rows: 1fr;
      grid-gap: 25px;
      width: 100%;
      &-item {
        background: #f5f5f5;
        border-radius: 14px;
        display: none;
        &-title {
          margin-left: 15px;
          margin-top: 21px;
          margin-right: 15px;
          margin-bottom: 15px;
          font-weight: 600;
          font-size: 20px;
          line-height: 136%;
          color: #000000;
        }
        &-content {
          margin-left: 15px;
          margin-right: 15px;
          margin-bottom: 5px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          height: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
          p {
            margin-bottom: 0px !important;
          }
        }
        a {
          margin-right: 10px;
          float: right;
          margin-bottom: 20px;
          color: #0047ff !important;
          margin-top: 10px;
        }
        svg {
          width: 23px;
        }
      }
    }
  }
}
.dropdown-option {
  max-height: 180px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
    cursor: default;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
  }
  .ant-dropdown-menu-item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
    margin-top: 10px;
    display: block;
  }
}

.scroll-notify {
  &::-webkit-scrollbar {
    width: 5px;
    cursor: default;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
  }
}

@media (max-width: 992px) {
  .collapse {
    .profile {
      padding-left: 58px;
    }
  }
}

.hamburger{
  height: 70px;
  width: 70px;
  display:flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
.hamburger span{
  position: absolute;
  width: 33px;
  height: 3px;
  border-radius: 30px;
  background-color: #17469E;
}
.hamburger span:nth-child(1){
  transform: translateY(-10px);
}
.hamburger span:nth-child(3){
  transform: translateY(10px);
}
.hamburger.active span:nth-child(2){
  scale: 0;
}
.hamburger.active span:nth-child(1){
  transform: rotate(-135deg);
  background-color: #EC2624;
}
.hamburger.active span:nth-child(3){
  transform: translateY(0) rotate(315deg);
  background-color: #EC2624;
}
.hamburger span{
  transition: all .4s ease-in-out;
}
.list-category{
  max-height: 235px;
  overflow: auto;
  top: 65px;
  left: 28px;
  opacity: 0;
  transition: all .5s ease-in-out;
}
.list-category.active{
  top: 50px;
  opacity: 1;
}
.chapter{
  mark{
    padding: 0 !important;
    background-color: #a2d8f6;
  }
  mark.current{
    background-color: #e5b32a;
  }
}